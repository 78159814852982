<template>
  <b-modal id="document-modal" hide-header hide-footer centered size="lg" @hide="onHidden">
    <div class="header">
      <div class="header-content">
        <p>Documentos anexados</p>
        <Close class="close-icon" @click="closeModal" />
      </div>
    </div>
    <div class="body">
      <div class="body-content">
        <div class="container">
          <div class="d-flex justify-content-between">
            <span v-if="documents.length === 1">
              {{ documents[0]?.custom_name }}
            </span>
            <span v-else/>
            <b-button variant="link" class="openDocsTab" @click="openDocsTab()">
              Abrir em nova aba
              <Export class="icon" />
            </b-button>
          </div>

          <div v-if="urls.length" class="documents">
            <ImageViewer v-if="!isPdf" :images="urls" />
            <iframe
              v-else
              class="pdf-viewer"
              title="Documentos da anexados"
              :src="urls[0]"
            />
          </div>
          <b-skeleton-img v-else />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <button @click="closeModal">Fechar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mergeAllPDFs } from '@/utils/PDFHelper'

export default {
  name: 'DocumentModal',
  props: {
    documents: Array
  },
  components: {
    ImageViewer: () => import('@/components/General/ImageViewer'),
    Close: () => import('@/assets/icons/close.svg'),
    Export: () => import('@/assets/icons/export.svg')
  },
  data: () => ({
    urls: []
  }),
  computed: {
    isPdf() {
      return this.documents.some(doc => doc.path.includes('.pdf'))
    }
  },
  methods: {
    async getDocTemporaryUrl(path) {
      const response = await this.api.getDocTemporaryUrl(path)
      this.urls.push(response.data)
    },
    onHidden() {
      this.urls = []
    },
    closeModal() {
      this.onHidden()
      this.$bvModal.hide('document-modal')
      this.$emit('close-modal')
    },
    async showDocuments(documents) {
      try {
        if (documents.some(doc => doc.path.includes('.pdf'))) {
          const url = await mergeAllPDFs(documents)
          this.urls.push(url)
        } else {
          documents.forEach(async doc => {
            await this.getDocTemporaryUrl(doc.path)
          })
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getDocUrl(path) {
      const loading = this.$loading.show()
      try {
        const { data } = await this.api.getDocTemporaryUrl(path)
        return data
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async openDocsTab() {
      this.documents.forEach(async doc => {
        const url = await this.getDocUrl(doc.path)
        window.open(url, '_blank')
      })
    }
  },
  watch: {
    documents: {
      async handler(value) {
        if (value.length) {
          if (
            value.length === 1 &&
            value[0].path.includes('Historico Paciente')
          )
            await this.getDocTemporaryUrl(value[0].path)
          else await this.showDocuments(value)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#document-modal {
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;
      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }

          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 500px;
            padding: 24px;

            .openDocsTab {
              display: flex;
              flex-direction: row;
              justify-content: end;
              align-items: center;
              padding-bottom: 10px;

              .icon {
                border: none;
                stroke: none;
                margin-left: 8px;
              }
            }

            .documents {
              width: 100%;
              height: 500px;

              .pdf-viewer {
                width: 100%;
                height: 100%;
                border: none !important;
              }
            }
          }
        }
      }

      .footer {
        .footer-content {
          display: flex;
          justify-content: right;
          margin: 24px;

          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            border-radius: 8px;
            background-color: var(--blue-500);
            color: var(--neutral-000);
          }
        }
      }
    }
  }
}
</style>
